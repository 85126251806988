<template>
  <section
    :class="[
      {'lg:mx-16 xl:mx-32': $route.name === '*' && index % 2 === 0},
      {'lg:mr-24 xl:mr-48': $route.name === '*' && index % 2 !== 0}
    ]"
    class="flex"
  >
    <img
      :src="item.image && item.image.url"
      alt="item.title"
      aria-hidden="true"
      :class="index % 2 === 0 ? 'order-1' : 'order-2'"
      class="hidden sm:block w-1/4 md:w-1/3 object-cover object-center"
    >
    <article
      :class="index % 2 === 0 ? 'order-2 bg-white px-12 md:pr-0 md:pl-32' : 'order-1 bg-background px-12 md:pr-0 md:pl-32 xl:pl-40 xxl:pl-56'"
      class="sm:w-3/4 md:w-2/3 flex flex-col justify-center py-10 sm:py-24 sm:-mt-12 z-30"
    >
      <h2 v-text="item.title"/>
      <div class="md:w-3/4 pt-3 pb-6" v-html="item.text"/>
      <nuxt-link
        v-if="item.page"
        :to="`/${item.page.slug}`"
        class="btn btn-primary w-40"
      >
        Meer informatie
      </nuxt-link>
    </article>
  </section>
</template>

<script>
export default {
  props: {
    index: {
      type: Number,
      default: 0,
    },
    item: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>
