<template>
  <section
    :class="$route.name === '*' ? '-mt-53p pt-32 pb-40 relative z-20' : 'pt-16 pb-20 sm:pb-32 md:py-32'"
    class="bg-cover"
    :style="`background-image: url('${require('~/assets/img/bg.png')}');`"
  >
    <div class="flex flex-col md:flex-row items-center">
      <article class="md:w-1/2 px-4 sm:px-12 lg:pl-32 lg:pr-20 xxl:px-48">
        <h2>{{ page.template.titleConfigurator }}</h2>
        <div class="text-white leading-relaxed pt-3" v-html="page.template.textConfigurator"/>
      </article>
      <div class="flex flex-col items-center md:w-5/12 pt-20 md:pt-0 md:px-20 xxl:pr-32">
        <img
          :src="require('~/assets/img/icon.svg')"
          alt=""
          aria-hidden="true"
          class="object-contain h-12"
        >
        <h2 class="text-white text-center pt-5 pb-8">
          Stel uw ideale poort samen
        </h2>
        <nuxt-link
          :to="localeRoute({name: 'configurator-slug'})"
          class="btn btn-secondary text-sm"
        >
          Start de configurator
        </nuxt-link>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    page: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>
