<template>
  <section>
    <img
      :src="page.image && page.image.url"
      :alt="page.title"
      aria-hidden="true"
      class="object-cover object-center w-full h-55v"
    >
    <configuration-row :page="page"/>
    <two-column-row
      v-for="(item, index) in page.template.blocks"
      :key="item.title"
      :item="item"
      :index="index"
    />
  </section>
</template>

<script>
import page from '~/plugins/mixins/page';
import ConfigurationRow from '~/components/ConfigurationRow';
import TwoColumnRow from '~/components/TwoColumnRow';

export default {
  components: {
    ConfigurationRow,
    TwoColumnRow,
  },
  mixins: [page],
};
</script>
